import { fromUnixTime, isAfter, isBefore, setHours, setMinutes } from "date-fns";
import { MonitoringHours } from "../api/types/MonitoringHours";
import { Reading } from "../api/types/Reading";

const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

const getDateFrom24HourFormat = (d: Date, timeString: string) => {
  const [hours, minutes] = timeString.split(":");
  return setMinutes(setHours(d, +hours), +minutes);
};

export const isWithinMonitoringHours = (d: Date, monitoringHours: MonitoringHours[]) => {
  const dayOfWeek = daysOfWeek[d.getDay()];

  const m = monitoringHours.find((m) => m.dayOfWeek === dayOfWeek);

  if (!monitoringHours.length) {
    return true;
  }

  if (!m) {
    return false;
  }

  const startMonitoring = getDateFrom24HourFormat(d, m.startTime);
  const endMonitoring = getDateFrom24HourFormat(d, m.endTime);

  return isAfter(d, startMonitoring) && isBefore(d, endMonitoring);
};

export const getReadingsWithinMonitoringHours = (
  readings: Reading[],
  monitoringHours: MonitoringHours[]
): Reading[] => {
  return readings.filter((r) => isWithinMonitoringHours(fromUnixTime(r[0]), monitoringHours));
};
