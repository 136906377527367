import * as React from "react";

const HumidityAreaIcon = ({
  width = 23,
  height = 23,
  color = "#111928",
}: {
  width?: number;
  height?: number;
  color?: string;
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 23 23">
    <g clipPath="url(#a)" fill={color}>
      <path d="M8.041 12.7a.263.263 0 0 1-.265-.264v-.006a.266.266 0 1 1 .53 0c0 .148-.118.27-.265.27ZM14.841 2.699c-.195-.266-.364-.495-.397-.548A.276.276 0 0 0 14.205 2a.266.266 0 0 0-.264.238c-.09.16-.45.66-.828 1.188-.918 1.279-2.455 3.419-3.686 5.528a.267.267 0 0 0 .228.402c.092 0 .18-.047.23-.132 1.218-2.088 2.746-4.215 3.659-5.486.334-.466.55-.767.688-.97l.183.248c4.017 5.448 6.054 9.205 6.054 11.164 0 3.466-2.81 6.287-6.264 6.287-3 0-5.582-2.143-6.14-5.095a.268.268 0 0 0-.31-.212.267.267 0 0 0-.212.312A6.79 6.79 0 0 0 14.205 21c3.746 0 6.794-3.06 6.794-6.82C21 11.051 16.365 4.764 14.841 2.7Z" />
      <path d="M7.035 10.191a1.98 1.98 0 0 1-1.16-.391c-.847-.61-1.626-.614-2.455-.017a.265.265 0 0 1-.37-.061.267.267 0 0 1 .06-.372c1.012-.73 2.046-.724 3.073.016.546.392 1.146.395 1.69.007 1.035-.739 2.039-.752 3.067-.04.625.434 1.25.413 1.91-.06.817-.589 1.707-.638 2.575-.142a.266.266 0 0 1-.263.464c-.692-.397-1.348-.36-2.002.111-.84.604-1.712.627-2.521.066-.846-.585-1.603-.575-2.457.035a1.965 1.965 0 0 1-1.147.384ZM11.839 13.027c-.408 0-.81-.135-1.2-.404a.267.267 0 0 1-.068-.371.264.264 0 0 1 .37-.068c.625.432 1.25.413 1.91-.061.816-.588 1.707-.637 2.574-.141a.268.268 0 0 1 .099.363.265.265 0 0 1-.362.1c-.692-.396-1.348-.36-2.002.112-.437.313-.88.47-1.321.47ZM7.035 13.042c-.399 0-.797-.13-1.16-.392-.846-.609-1.626-.614-2.454-.017a.265.265 0 0 1-.37-.06.268.268 0 0 1 .06-.373c1.011-.729 2.045-.724 3.072.016.547.392 1.147.395 1.69.007a.265.265 0 0 1 .37.062.267.267 0 0 1-.061.372 1.962 1.962 0 0 1-1.147.385ZM7.035 15.893c-.398 0-.797-.131-1.16-.393-.846-.608-1.627-.613-2.454-.016a.267.267 0 0 1-.31-.433c1.011-.73 2.045-.724 3.072.016.547.392 1.148.394 1.691.006 1.035-.738 2.038-.75 3.066-.039.626.433 1.25.413 1.91-.06.817-.59 1.707-.638 2.575-.142a.266.266 0 0 1-.263.464c-.693-.396-1.348-.36-2.002.111-.84.604-1.711.628-2.52.066-.846-.585-1.604-.575-2.458.035-.36.256-.753.385-1.147.385Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(3 2)" d="M0 0h18v19H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default HumidityAreaIcon;
