import { HStack, Text } from "@chakra-ui/react";
import numeral from "numeral";
import React from "react";
import { NewBatteryLevelIndicator } from "../../indicators/NewBatteryLevelIndicator";

interface BatteryLevelProps {
  battery: number;
}

export const BatteryLevel = ({ battery }: BatteryLevelProps) => {
  if (battery > 1) {
    battery = battery / 100;
  }
  const batteryText = numeral(battery).format("0[.]00%");
  return (
    <HStack alignItems="center">
      <NewBatteryLevelIndicator
        batteryLevel={battery}
        //  size={30}
        tooltipLabel={`Battery Level: ${batteryText}`}
      />
    </HStack>
  );
};
