import React from "react";
import { Box } from "@chakra-ui/layout";
import { Tooltip } from "@chakra-ui/tooltip";
import { NetworkConnectionIcon } from "../icons/NetworkConnectionIcon";
import numeral from "numeral";

interface SignalStrengthIndicatorProps {
  signalStrength: number;
}

function SignalStrengthIndicator({ signalStrength }: SignalStrengthIndicatorProps) {
  const percentageString = numeral(signalStrength).format("0[.]00%");

  const tooltipLabel = `Signal: ${percentageString}`;

  return (
    <Tooltip hasArrow label={tooltipLabel} aria-label={tooltipLabel}>
      <Box>
        <NetworkConnectionIcon connection={signalStrength} />
      </Box>
    </Tooltip>
  );
}

export default SignalStrengthIndicator;
