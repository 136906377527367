import * as React from "react";

const SoundIcon = ({
  width = 28,
  height = 19,
  fill = "#111928",
}: {
  width?: number;
  height?: number;
  fill?: string;
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 28 19">
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={9}
      width={5}
      height={1}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9.01h4.457v.584H0V9.01Z"
        fill={fill === "#111928" ? "#fff" : "#111"}
      />
    </mask>
    <g mask="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.174 9.594H.283A.287.287 0 0 1 0 9.302c0-.162.126-.292.283-.292h3.891c.157 0 .283.13.283.292a.287.287 0 0 1-.283.292Z"
        fill={fill}
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.717 9.594h-3.891a.287.287 0 0 1-.283-.292c0-.162.126-.292.283-.292h3.891c.157 0 .283.13.283.292a.287.287 0 0 1-.283.292ZM6.155 11.645a.287.287 0 0 1-.283-.293V7.48c0-.161.127-.292.283-.292.156 0 .283.13.283.292v3.872a.287.287 0 0 1-.283.293ZM21.61 12.664a.287.287 0 0 1-.284-.292v-5.91c0-.162.127-.293.283-.293.156 0 .283.131.283.293v5.91a.287.287 0 0 1-.283.292ZM8.457 14.566a.287.287 0 0 1-.283-.292V4.56c0-.162.126-.292.283-.292.156 0 .282.13.282.292v9.714a.287.287 0 0 1-.282.292ZM19.373 14.566a.287.287 0 0 1-.282-.292V4.56c0-.162.126-.292.282-.292.156 0 .283.13.283.292v9.714a.288.288 0 0 1-.283.292ZM10.627 13.615a.287.287 0 0 1-.283-.293V5.51c0-.161.127-.292.283-.292.156 0 .283.131.283.292v7.813a.288.288 0 0 1-.283.292ZM12.797 18.166a.287.287 0 0 1-.283-.292V.96c0-.162.127-.292.283-.292.156 0 .283.13.283.292v16.915a.287.287 0 0 1-.283.293ZM14.967 15.924a.287.287 0 0 1-.282-.292V3.2c0-.161.126-.292.282-.292.157 0 .283.13.283.292v12.432a.287.287 0 0 1-.283.292ZM17.137 17.487a.287.287 0 0 1-.282-.292V1.638c0-.162.126-.292.282-.292.156 0 .283.13.283.292v15.557a.287.287 0 0 1-.283.292Z"
      fill={fill}
    />
  </svg>
);

export default SoundIcon;
