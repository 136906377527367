import * as React from "react";

const HealthyHeartIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={11} height={9} fill="none">
    <path
      d="M5.25 9c.106 0 .257-.069.368-.132C8.452 7.103 10.25 5.049 10.25 2.96 10.25 1.225 9.026 0 7.445 0 6.463 0 5.708.53 5.25 1.338 4.802.534 4.037 0 3.055 0 1.474 0 .25 1.225.25 2.96c0 2.089 1.798 4.143 4.637 5.908.106.063.257.132.363.132Z"
      fill="#6DC96E"
    />
  </svg>
);

export default HealthyHeartIcon;
