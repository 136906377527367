import React from "react";
import { Box, Flex, Text } from "@chakra-ui/layout";
import HealthyHeartIcon from "../areas-icons/HealthyHeartIcon";
import UnhealthyHeartIcon from "../areas-icons/UnhealthyHeartIcon";

export type DeviceReadingModalItemIcon = {
  icon: JSX.Element;
  title: string;
  reading: string | number;
};
interface DeviceReadingModalItemProps extends DeviceReadingModalItemIcon {
  hasHealthyReading: boolean;
  ignoreHealthReading?: boolean;
}

export const DeviceReadingModalItem = ({
  hasHealthyReading,
  icon,
  title,
  reading,
  ignoreHealthReading = false,
}: DeviceReadingModalItemProps) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" pb={4}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        boxSize="50px"
        borderRadius="full"
        bg="gray.50"
      >
        {icon}
      </Box>
      <Text pt="6px" color="gray.primary">
        {title}
      </Text>
      <Flex flexDirection="row" alignItems="center">
        {Boolean(!ignoreHealthReading) && (
          <>{hasHealthyReading ? <HealthyHeartIcon /> : <UnhealthyHeartIcon />}</>
        )}
        <Text pl="4px" fontSize={18}>
          {reading}
        </Text>
      </Flex>
    </Box>
  );
};
