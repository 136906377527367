import React, { useState } from "react";
import { Button } from "@chakra-ui/button";

interface PrimaryButtonProps {
  height?: number;
  text?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: () => void | Promise<any>;
  type?: "button" | "submit" | "reset";
  width?: number;
  loading?: boolean;
  size?: string;
  disabled?: boolean;
  className?: string;
}

export const PrimaryButton = ({
  height,
  text,
  onClick,
  type,
  width,
  loading,
  size,
  className,
  disabled = false,
}: PrimaryButtonProps) => {
  const [processing, setProcessing] = useState(false);

  const handleClick = async () => {
    const result = onClick && onClick();
    if (result instanceof Promise) {
      setProcessing(true);
      await result;
      setProcessing(false);
    }
  };

  return (
    <>
      <Button
        isLoading={loading || processing}
        height={height}
        colorScheme="blue"
        width={width}
        onClick={handleClick}
        type={type}
        disabled={disabled || loading || processing}
        size={size}
        className={className}
      >
        {text}
      </Button>
    </>
  );
};
