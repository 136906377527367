import * as React from "react";

const UnhealthyHeartIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={10} fill="none">
    <path
      d="M5.75 10c.116 0 .282-.076.404-.147C9.273 7.893 11.25 5.61 11.25 3.29 11.25 1.362 9.904 0 8.165 0 7.085 0 6.254.588 5.75 1.487 5.257.594 4.415 0 3.335 0 1.595 0 .25 1.362.25 3.29c0 2.32 1.977 4.602 5.101 6.563.117.07.283.147.399.147Z"
      fill="#FB5169"
    />
    <path
      d="M5.364 5.847h.772L6.21 3h-.92l.074 2.847Zm.386 1.478c.28 0 .5-.205.5-.467s-.22-.467-.5-.467-.5.205-.5.467.22.467.5.467Z"
      fill="#fff"
    />
  </svg>
);

export default UnhealthyHeartIcon;
