import * as React from "react";

const AirwitzPMIcon = ({ size }: { size: number }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 34 34" fill="none">
    <path
      d="M23.607 20.03c-2.606 0-4.726-2.135-4.726-4.759 0-2.623 2.12-4.757 4.726-4.757 2.607 0 4.727 2.134 4.727 4.757 0 2.624-2.12 4.758-4.727 4.758Zm0-8.65c-2.13 0-3.865 1.746-3.865 3.891 0 2.146 1.734 3.891 3.865 3.891 2.132 0 3.865-1.745 3.865-3.89 0-2.146-1.733-3.891-3.865-3.891ZM11.598 11.381c-1.565 0-2.838-1.281-2.838-2.857 0-1.576 1.273-2.857 2.838-2.857 1.565 0 2.838 1.281 2.838 2.857 0 1.576-1.273 2.857-2.838 2.857Zm0-4.847c-1.09 0-1.977.893-1.977 1.99s.887 1.99 1.977 1.99 1.977-.893 1.977-1.99c0-1.098-.887-1.99-1.977-1.99Z"
      fill="#021238"
    />
    <path
      d="M11.6 10.963a2.45 2.45 0 0 0 2.441-2.457A2.45 2.45 0 0 0 11.6 6.048a2.45 2.45 0 0 0-2.442 2.458 2.45 2.45 0 0 0 2.442 2.457ZM23.594 19.543c2.342 0 4.241-1.91 4.241-4.268 0-2.358-1.899-4.27-4.24-4.27-2.343 0-4.241 1.912-4.241 4.27 0 2.357 1.898 4.268 4.24 4.268Z"
      stroke="#021238"
      strokeMiterlimit={10}
    />
    <path
      d="M11.621 28.334c-3.283 0-5.954-2.69-5.954-5.994 0-3.305 2.671-5.993 5.954-5.993s5.954 2.688 5.954 5.993-2.67 5.994-5.954 5.994Zm0-10.895c-2.684 0-4.869 2.199-4.869 4.901 0 2.703 2.184 4.901 4.87 4.901 2.684 0 4.868-2.198 4.868-4.9 0-2.704-2.184-4.902-4.869-4.902Z"
      fill="#021238"
    />
  </svg>
);

export default AirwitzPMIcon;
