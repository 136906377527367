import React, { useEffect, useState } from "react";
import { Box, Text, Button, Alert, AlertIcon, useToast, Link } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import moment from "moment";
import { GatewaySeenDevice, useGetDevice, useGetGatewaySeenDevices } from "../../../lib/api/devices/hooks";
import { DeviceWithLastSample } from "../../../lib/api/types/DeviceWithLastSample";
import { useGetPropertyDetail } from "../../../lib/api/properties/hooks";
import { getDeviceIcon } from "./getDeviceIcon";
import { formatDeviceName } from "../../../lib/utils/formatDeviceName";
import { PropertyInfo } from "../../../lib/api/types/PropertyInfo";
import { Device } from "../../../lib/api/types/Device";
import { PropertyDetail } from "../../../lib/api/types/PropertyDetail";
import NextLink from "next/link";
import { formatCircuitPhaseTitle } from "../circuits/formatCircuitPhaseTitle";

export const GatewayLinkedDevices = ({
  device,
  installationId,
}: {
  device: DeviceWithLastSample;
  installationId: string;
}) => {
  const { data, isLoading, refetch, error } = useGetGatewaySeenDevices(device.id);
  const [isSearching, setIsSearching] = useState(false);
  const now = moment();
  const [lastCheck, setLastCheck] = useState<moment.Moment>(now);

  let timeout: NodeJS.Timeout;

  useEffect(() => {
    (async () => {
      console.log("Running use effect", lastCheck, isSearching);
      try {
        if (timeout) clearTimeout(timeout);

        if (!isSearching) return;

        console.log("Setting timer");
        // eslint-disable-next-line react-hooks/exhaustive-deps
        timeout = setTimeout(() => {
          console.log("Running timer", refetch);
          if (!refetch) return;
          const now = moment();
          setLastCheck(now);
          refetch();
        }, 10000);

        return () => {
          console.log("Clearing timer");
          clearTimeout(timeout);
        };
      } catch (error) {
        console.error("Could not fetch power", error);
      }
    })();
  }, [isSearching, refetch, lastCheck]);

  const numberLinked = data?.length + " " || "";

  return (
    <Box w="100%">
      <Text ml={4} fontSize="lg" fontWeight="bold">
        {isLoading ? "Loading..." : numberLinked + "Linked Devices"}
      </Text>

      {data?.map((device) => (
        <GatewayLinkedDevice installationId={installationId} key={device.deviceId} seenDevice={device} />
      ))}

      <Box>
        {isSearching && (
          <Text fontSize="lg" fontWeight="bold">
            Searching...
          </Text>
        )}

        {Boolean(error) && (
          <Alert status="error">
            <AlertIcon />
            {(error as Error).message}
          </Alert>
        )}

        <Button
          p={3}
          variant="outline"
          onClick={() => {
            setIsSearching(!isSearching);
            if (refetch) refetch();
          }}
        >
          {isSearching ? "Stop Searching" : "Search for devices"}
        </Button>

        {Boolean(lastCheck) && <Text mt={2}>Last checked for devices {lastCheck.format("HH:mm:ss")}</Text>}
      </Box>
    </Box>
  );
};

interface RoomInfo {
  roomId: string | undefined;
  roomName: string | undefined;
}

const GatewayLinkedDevice = ({
  seenDevice,
  installationId,
}: {
  seenDevice: GatewaySeenDevice;
  installationId: string;
}) => {
  const toast = useToast();

  const { data, error } = useGetDevice(seenDevice.deviceId);
  const { propertyInfo } = useGetPropertyDetail(installationId);

  function findRoomIdByDeviceId(
    deviceId: string | undefined,
    propertyInfo: PropertyDetail | undefined
  ): RoomInfo {
    const idToMatch = deviceId;

    if (!idToMatch || !propertyInfo?.rooms) {
      return { roomId: undefined, roomName: undefined };
    }
    const matchingRoom = propertyInfo.rooms.find((room) => {
      const foundRl = room.roomLocations?.find((roomLocation) => roomLocation?.device?.id === idToMatch);
      if (foundRl) {
        return true;
      }
      return false;
    });

    if (matchingRoom) {
      return { roomId: matchingRoom.id, roomName: matchingRoom.name };
    }

    return { roomId: undefined, roomName: undefined };
  }
  const matchingRoomInfo = findRoomIdByDeviceId(data?.id, propertyInfo);

  const id = data && data?.id;

  const lastCheckin = seenDevice.lastSeenTimestamp ? moment(seenDevice.lastSeenTimestamp).fromNow() : "Never";
  let title = data ? formatDeviceName(data.type) : "Loading...";
  if (error) title = "Could not get device information";

  const circuit = propertyInfo?.circuits.find((circuit) => circuit?.device?.id === id);

  let urlToOpen: string | undefined = undefined;
  if (matchingRoomInfo.roomId) {
    urlToOpen = "/area/room/" + matchingRoomInfo.roomId;
  }

  if (circuit) {
    urlToOpen = "/circuitnode/" + circuit.circuitNodeId;
  }

  return (
    <Box
      // h={"268px"}
      height="100%"
      shadow="md"
      bg="white"
      borderRadius={8}
      borderWidth={1}
      borderColor="gray.50"
      display="flex"
      flexDirection="column"
      p={4}
      mb={2}
    >
      <Box d="flex" justifyContent="space-between" alignItems="left">
        <Text fontWeight="medium" fontSize="md">
          <Box flexDirection="row" justifyContent="space-between" alignItems="center">
            {Boolean(matchingRoomInfo?.roomName) && (
              <Text fontWeight={500} fontSize={18}>
                {matchingRoomInfo.roomName}
              </Text>
            )}
            {Boolean(data?.type === "HOTDROP" && circuit) && (
              <Text fontWeight={500} fontSize={15}>
                {circuit ? formatCircuitPhaseTitle(circuit, true) : ""}
              </Text>
            )}
          </Box>
        </Text>

        {Boolean(urlToOpen) && (
          <NextLink href={urlToOpen!} passHref style={{ border: "1px solid #333" }} legacyBehavior>
            <Link textDecoration={"underline"}>Open Device</Link>
          </NextLink>
        )}
      </Box>

      <Box d="flex">
        <Box mr={2}>
          {/* Assume getDeviceTypeImage returns an image component */}
          {data?.type && getDeviceIcon(data.type, 40)}
        </Box>
        <Box d="flex" flexDirection="column">
          <Text>{title}</Text>
          {Boolean(data?.shortSerial) && <Text>Serial: {data?.shortSerial}</Text>}
          {Boolean(data?.sku) && <Text>SKU: {data?.sku}</Text>}
        </Box>
      </Box>

      <Box mt={2}>
        <Text>Last Seen: {lastCheckin}</Text>
      </Box>
    </Box>
  );
};
