import { Box, Circle, Grid, HStack } from "@chakra-ui/layout";
import { useTheme } from "@chakra-ui/react";
import React from "react";
import { GraphColor, GraphColorDetails } from "../../lib/api/types/Room";

interface RoomColorPickerProps {
  selectedColor: GraphColor;
  onSelectColor: (color: GraphColor) => void;
}
const roomColors = Object.values(GraphColor);

export const RoomColorPicker = ({ selectedColor, onSelectColor }: RoomColorPickerProps) => (
  <HStack
    display="inline-flex"
    boxShadow="lg"
    borderRadius={8}
    borderWidth={1}
    borderColor="gray.50"
    p={2}
    gap={2}
  >
    {roomColors.map((c, index) => (
      <RoomColorItem
        key={index}
        color={c}
        isSelected={c === selectedColor}
        onClick={() => onSelectColor(c)}
      />
    ))}
  </HStack>
);

interface RoomColorItemProps {
  color: GraphColor;
  isSelected: boolean;
  onClick: () => void;
}

export const RoomColorItem = ({ color, isSelected, onClick }: RoomColorItemProps) => {
  const theme = useTheme();
  return (
    <Box
      w={9}
      h={9}
      p={1}
      borderRadius={8}
      borderColor={isSelected ? theme.colors.blue[300] : theme.colors.gray[100]}
      borderWidth={isSelected ? 2 : 1}
      cursor="pointer"
      onClick={onClick}
      _hover={{
        opacity: 0.7,
      }}
    >
      <Box
        w={"100%"}
        h="100%"
        borderRadius={8}
        // White is supported by the mobile app but not visible on the graph - allow it to be seen but not selectable.
        // Also default to this if colour is null as it is we don't have an empty state.
        backgroundColor={color && GraphColorDetails[color] ? GraphColorDetails[color].color : "white"}
      ></Box>
    </Box>
  );
};
