import { DeviceType } from "../../../lib/api/types/GenericDevice";
import { PIDeviceTypes } from "../../../lib/api/types/PropertyInfo";
import { formatDeviceName } from "../../../lib/utils/formatDeviceName";
import EnviroQIcon from "../areas-icons/EnviroQIcon";

export const getDeviceIcon = (deviceType: string | DeviceType | undefined, maxWidth = 40) => {
  const styles = {
    maxWidth,
  };

  if (!deviceType) {
    return <img style={styles} src="/assets/devices/unknown.png" alt={"Device not found"} />;
  }

  deviceType = deviceType.toString().toLowerCase();
  if (deviceType === "airwits") {
    return <img style={styles} src="/assets/devices/airwits.png" alt={formatDeviceName(deviceType)} />;
  }

  if (deviceType === "airwits_iaq") {
    return <img style={styles} src="/assets/devices/airwits.png" alt={formatDeviceName(deviceType)} />;
  }

  if (deviceType === "airwits_co2") {
    return <img style={styles} src="/assets/devices/airwits.png" alt={formatDeviceName(deviceType)} />;
  }

  if (deviceType === "airwits_co2_plus") {
    return <img style={styles} src="/assets/devices/airwits.png" alt={formatDeviceName(deviceType)} />;
  }

  if (deviceType === "airwits_pm") {
    return <img style={styles} src="/assets/devices/airwits-pm.png" alt={formatDeviceName(deviceType)} />;
  }

  if (deviceType === "detectify") {
    return <img style={styles} src="/assets/devices/detectify.png" alt={formatDeviceName(deviceType)} />;
  }

  if (deviceType === "hotdrop") {
    return <img style={styles} src="/assets/devices/hotdrop.png" alt={formatDeviceName(deviceType)} />;
  }

  if (deviceType === "voltdrop") {
    return <img style={styles} src="/assets/devices/voltdrop.png" alt={formatDeviceName(deviceType)} />;
  }

  if (["adeunis_pulse", "adeunis_pulse_water"].includes(deviceType)) {
    return <img style={styles} src="/assets/devices/pulse.png" alt={formatDeviceName(deviceType)} />;
  }

  if (deviceType === "enviroq" || deviceType === "thermalq") {
    return <img style={styles} src="/assets/devices/enviroq.png" alt={formatDeviceName(deviceType)} />;
  }

  if (deviceType === "enviroq" || deviceType === "thermalq") {
    return <img style={styles} src="/assets/devices/enviroq.png" alt={formatDeviceName(deviceType)} />;
  }

  if (deviceType === "gateway") {
    return <img style={styles} src="/assets/devices/gateway.png" alt={formatDeviceName(deviceType)} />;
  }

  if (deviceType === "enviroq" || deviceType === "thermalq") {
    return <img style={styles} src="/assets/devices/enviroq.png" alt={formatDeviceName(deviceType)} />;
  }

  if (deviceType === "gateway") {
    return <img style={styles} src="/assets/devices/gateway.png" alt={formatDeviceName(deviceType)} />;
  }

  if (deviceType === "flexsense") {
    return <img style={styles} src="/assets/devices/flexsense.png" alt={formatDeviceName(deviceType)} />;
  }

  if (deviceType === "room_radar" || deviceType === "room_radar_line" || deviceType === "room_radar_region") {
    return <img style={styles} src="/assets/devices/room-radar.png" alt={formatDeviceName(deviceType)} />;
  }

  return <img style={styles} src="/assets/devices/unknown.png" alt={formatDeviceName(deviceType)} />;
};

export const imageForDeviceType = (deviceType: DeviceType | PIDeviceTypes) => {
  const dType = deviceType.toLowerCase();

  const acceptedDeviceTypes = [
    "enviroq",
    "thermalq",
    "airwits",
    "airwits_iaq",
    "airwits_co2",
    "airwits_co2_plus",
    "airwits_pm",
    "detectify",
    "hotdrop",
    "gateway",
    "flexsense",
    "voltdrop",
    "room_radar",
    "adeunis_pulse",
    "adeunis_pulse_water",
  ];

  if (acceptedDeviceTypes.indexOf(dType) === -1) {
    return "unknown.png";
  }

  if (["adeunis_pulse", "adeunis_pulse_water"].includes(dType)) {
    return "pulse.png";
  }

  if (["airwits", "airwits_iaq", "airwits_co2", "airwits_co2_plus"].includes(dType)) {
    return "airwits.png";
  }

  const url = dType.replaceAll(" ", "-").replaceAll("_", "-") + ".png";

  return url;
};
