import * as React from "react";

const LowBatteryAreasIcons = ({ width = 19, height = 8 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0  0 19 8 ">
    <path
      d="M3.421 8h10.09c1.099 0 2.023-.095 2.678-.698.654-.603.75-1.442.75-2.454V3.152c0-1.012-.096-1.851-.75-2.454C15.534.095 14.609 0 13.512 0H3.408C2.33 0 1.406.095.751.698.096 1.3 0 2.146 0 3.134v1.714C0 5.86.096 6.7.751 7.302c.655.603 1.58.698 2.67.698Zm-.173-.952c-.661 0-1.354-.083-1.746-.438-.385-.36-.469-.993-.469-1.602V3.004c0-.621.084-1.254.469-1.615.392-.36 1.091-.437 1.765-.437h10.425c.66 0 1.354.083 1.74.438.39.36.474.987.474 1.596v2.022c0 .61-.083 1.242-.475 1.603-.385.354-1.078.437-1.74.437H3.249Zm-.34-.686h1.245c.443 0 .7-.059.88-.225.18-.165.243-.402.243-.81V2.673c0-.408-.064-.645-.244-.81-.18-.166-.442-.225-.879-.225H2.927c-.456 0-.719.059-.899.225-.173.165-.244.408-.244.821v2.643c0 .408.071.645.244.81.18.166.443.225.88.225Zm14.905-.834C18.32 5.498 19 4.902 19 3.997c0-.899-.68-1.496-1.188-1.525v3.056Z"
      fill="#111928"
    />
    <rect x={1.467} y={1.646} width={3.958} height={4.727} rx={1} fill="#FB5169" />
  </svg>
);

export default LowBatteryAreasIcons;
