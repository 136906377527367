import * as React from "react";

const PressureIcon = ({
  width = 23,
  height = 23,
  fill = "#111928",
}: {
  width?: number;
  height?: number;
  fill?: string;
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={23} height={23} fill="none" viewBox="0 0 23 23">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.388 22.263a.42.42 0 0 1-.425-.416v-1.984c0-1.627-.389-2.707-.732-3.66-.471-1.312-.878-2.445.223-4.139.194-.297.4-.574.604-.845.556-.742 1.037-1.383 1.127-2.316.075-.78-.218-1.46-.527-2.177-.298-.69-.607-1.405-.607-2.25V.75c0-.23.191-.417.427-.417.235 0 .426.187.426.417v3.725c0 .678.261 1.285.538 1.928.338.78.686 1.588.591 2.579-.112 1.16-.71 1.959-1.288 2.73a14.34 14.34 0 0 0-.572.8c-.88 1.351-.6 2.128-.138 3.414.347.966.78 2.168.78 3.937v1.984c0 .23-.191.416-.427.416Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.353 22.5a.43.43 0 0 1-.302-.122L.875 18.294a.41.41 0 0 1 0-.589.432.432 0 0 1 .602 0l3.876 3.79 3.875-3.79a.432.432 0 0 1 .602 0 .41.41 0 0 1 0 .59l-4.176 4.083a.43.43 0 0 1-.301.122ZM18.35 22.263a.421.421 0 0 1-.426-.416v-1.984c0-1.627-.388-2.708-.73-3.66-.472-1.313-.88-2.445.222-4.139.193-.297.4-.573.603-.844.556-.742 1.037-1.384 1.128-2.317.075-.781-.218-1.46-.527-2.177-.299-.69-.607-1.405-.607-2.25V.75c0-.23.19-.417.426-.417a.42.42 0 0 1 .426.417v3.725c0 .678.262 1.285.539 1.928.337.78.686 1.588.59 2.579-.111 1.161-.71 1.959-1.288 2.73-.192.257-.388.518-.571.8-.88 1.351-.6 2.128-.138 3.414.347.966.78 2.168.78 3.937v1.984a.42.42 0 0 1-.426.416Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.314 22.5a.43.43 0 0 1-.3-.122l-4.178-4.084a.41.41 0 0 1 0-.589.433.433 0 0 1 .603 0l3.875 3.79 3.875-3.79a.433.433 0 0 1 .603 0 .41.41 0 0 1 0 .59l-4.176 4.083a.43.43 0 0 1-.302.122Z"
      fill={fill}
    />
  </svg>
);

export default PressureIcon;
