import { BsReception0, BsReception1, BsReception2, BsReception3, BsReception4 } from "react-icons/bs";
import React from "react";

interface ConnectionResult {
  hasLowConnection?: boolean;
  hasFairConnection?: boolean;
  hasModerateConnection?: boolean;
  hasGoodConnection?: boolean;
  hasExcellentConnection?: boolean;
}

const resolveConnectionStatus = (connection: number): ConnectionResult => {
  if (connection > 0.75 && connection <= 1) {
    return {
      hasExcellentConnection: true,
    };
  }

  if (connection > 0.5 && connection <= 0.75) {
    return {
      hasGoodConnection: true,
    };
  }

  if (connection > 0.25 && connection <= 0.5) {
    return {
      hasModerateConnection: true,
    };
  }

  if (connection > 0.05 && connection <= 0.25) {
    return {
      hasFairConnection: true,
    };
  }

  if (connection >= 0 && connection <= 0.05) {
    return {
      hasLowConnection: true,
    };
  }

  // Default case (if none of the above conditions are met)
  return {};
};

export const NetworkConnectionIcon = ({ connection }: { connection: number }) => {
  const {
    hasExcellentConnection,
    hasGoodConnection,
    hasModerateConnection,
    hasFairConnection,
    hasLowConnection,
  } = resolveConnectionStatus(connection);

  const icons = [
    <BsReception0 size={20} />,
    <BsReception1 size={20} />,
    <BsReception2 size={20} />,
    <BsReception3 size={20} />,
    <BsReception4 size={20} />,
  ];

  const getIcon = (level: number) => icons[level] || null;

  return (
    <div>
      {getIcon(
        hasExcellentConnection
          ? 4
          : hasGoodConnection
          ? 3
          : hasModerateConnection
          ? 2
          : hasFairConnection
          ? 1
          : hasLowConnection
          ? 0
          : 0
      )}
    </div>
  );
};
